<template>
    <v-container fluid class="px-8">
        <div class="count-panel-container">
            <v-card
                class="mb-6"
                style="border-left: 5px solid #FFB74D !important;"
                color="white"
                width="23%"
                height="100%"
            >
                <v-card-text class="count-panel">
                    <v-icon large color="orange">
                        mdi-account-group
                    </v-icon>
                    <!-- <div class="text-h4 font-weight-bold white--text">
                        创建课程包
                    </div> -->
                    <div class="desc">
                        <div class="name">学生数</div>
                        <div class="count">{{ studentCount }}</div>
                    </div>
                </v-card-text>
            </v-card>
            <v-card
                class="mb-6"
                style="border-left: 5px solid #81C784 !important;"
                color="white"
                width="23%"
                height="100%"
            >
                <v-card-text class="count-panel">
                    <v-icon large color="green">
                        mdi-clipboard-text-outline
                    </v-icon>
                    <!-- <div class="text-h4 font-weight-bold white--text">
                        创建课程包
                    </div> -->
                    <div class="desc">
                        <div class="name">课程数</div>
                        <div class="count">{{ courseCount }}</div>
                    </div>
                </v-card-text>
            </v-card>
            <v-card
                class="mb-6"
                style="border-left: 5px solid #9575CD !important;"
                color="white"
                width="23%"
                height="100%"
            >
                <v-card-text class="count-panel">
                    <v-icon large color="purple">
                        mdi-calendar-check
                    </v-icon>
                    <!-- <div class="text-h4 font-weight-bold white--text">
                        创建课程包
                    </div> -->
                    <div class="desc">
                        <div class="name">学生练习数</div>
                        <div class="count">{{ exerciseCount }}</div>
                    </div>
                </v-card-text>
            </v-card>
            <v-card
                class="mb-6"
                style="border-left: 5px solid #64B5F6 !important;"
                color="white"
                width="23%"
                height="100%"
            >
                <v-card-text class="count-panel">
                    <v-icon large color="blue">
                        mdi-code-not-equal-variant
                    </v-icon>
                    <div class="desc">
                        <div class="name">学生代码数</div>
                        <div class="count">{{ codeLineCount }}</div>
                    </div>
                </v-card-text>
            </v-card>
        </div>

        <div class="row-container mb-6">
            <!-- <div class="stat-container"> -->
                <v-card
                    color="white"
                    width="65%"
                    height="100%"
                >
                    <div class="canvas-wrapper">
                        <div class="chart-wrapper">
                            <div class="empty-ranking" v-if="isRankingDataEmpty">
                                <div class="ranking-title">练习数量 Top 5</div>
                                <div class="ranking-desc">暂无学生数据</div>
                            </div>
                            <div id="chart_ranking" style="width: 100%; height: 268px;" v-else></div>
                        </div>
                    </div>
                </v-card>
            <!-- </div> -->
            <v-card width="33%" height="100%">
                <vc-calendar is-expanded :attributes='attributes' />
            </v-card>
        </div>

        <div class="row-container">
            <v-card
                color="white"
                width="100%"
                height="100%"
            >
                <div id="chart_heatmap" style="width: 100%; min-height: 240px;"></div>
            </v-card>
        </div>

    </v-container>
</template>

<script>
import * as echarts from 'echarts';
import { getAdminDashboard } from '@/api/admin';

export default {
    name: "Dashboard",

    data() {
        const year = new Date().getFullYear();
        return {
            attributes: [
                // This is a single attribute
                {
                    // An optional key can be used for retrieving this attribute later,
                    // and will most likely be derived from your data object
                    key: "calendar",
                    // Attribute type definitions
                    highlight: "red",  // Boolean, String, Object
                    // content: 'red',   // Boolean, String, Object
                    dates: new Date(),
                    // You can optionally provide dates to exclude
                    excludeDates: null,
                    // Think of `order` like `z-index`
                    order: 0,
                }
            ],
            curYear: year,
            chartRanking: null,
            chartHeatMap: null,

            isRankingDataEmpty: false,

            // 统计数据
            studentCount: 0,
            courseCount: 0,
            exerciseCount: 0,
            codeLineCount: 0,

            rankingTop5: null,

            // 定时刷新
            interval: null,
        };
    },
    mounted() {
        // this.loadUserInfo();
    },
    activated() {
        this.loadData()
        // if (!this.interval) {
        //     // 30秒自动刷新一次，为了更新dashboard中图表的数据
        //     this.interval = setInterval(this.loadData, 30000);
        // }
    },
    deactivated() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    },
    methods: {
        async loadUserInfo() {
            await this.$store.dispatch("user/getUserInfo");
        },
        loadData() {
            getAdminDashboard().then((response) => {
                // console.log(response.data);
                this.studentCount = response.data.studentCount
                this.courseCount = response.data.courseCount
                this.exerciseCount = response.data.exerciseCount
                this.codeLineCount = response.data.codeLineCount

                this.rankingTop5 = response.data.rankingTop5

                this.initECharts()
                
            }).catch(function (err) {
                console.log(err);
            });
        },
        initECharts() {
            let that = this

            this.initRanking()
            this.initHeatMap()

            window.onresize = function() {
                if (that.chartRanking) {
                    that.chartRanking.resize()
                }
                if (that.chartHeatMap) {
                    that.chartHeatMap.resize()
                }
            };
        },
        initRanking() {
            if (!this.rankingTop5 || this.rankingTop5['nameList'].length == 0) {
                this.isRankingDataEmpty = true
                return
            }

            if (!this.chartRanking) {
                let chartDom = document.getElementById('chart_ranking');
                this.chartRanking = echarts.init(chartDom);
            }

            let option = {
                title: {
                    show: true,
                    top: 20,
                    left: "center",
                    text: "练习数量 Top 5",
                },
                xAxis: {
                    max: 'dataMax',
                    axisLine: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'category',
                    // data: ['AA003001', 'AA003002', 'AA003003', 'AA003004', 'AA003005'],
                    data: this.rankingTop5['nameList'],
                    inverse: true,
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                    max: 4, //从0开始，所以实际是Top 5
                },
                grid: {
                    show: false,
                    top: 70,
                    left: 100,
                    right: 50,
                },
                series: [
                    {
                        realtimeSort: true,
                        name: 'X',
                        type: 'bar',
                        data: this.rankingTop5['countList'],
                        label: {
                            show: true,
                            position: 'right',
                            valueAnimation: true
                        }
                    }
                ],
                legend: {
                    show: false
                },
                aria: {
                    enabled: true,
                    decal: {
                        show: true
                    }
                },
                animationDuration: 0,
                animationDurationUpdate: 3000,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear'
            };
            option && this.chartRanking.setOption(option);

            this.$nextTick(() => {
                this.chartRanking.resize();
            })
        },
        getHeatMapData(year) {
            var date = +echarts.number.parseDate(year + '-01-01');
            var end = +echarts.number.parseDate(year + '-12-31');
            var dayTime = 3600 * 24 * 1000;
            var data = [];
            // for (var time = date; time <= end; time += dayTime) {
            //     data.push([
            //         echarts.format.formatTime('yyyy-MM-dd', time),
            //         Math.floor(Math.random() * 10000)
            //     ]);
            // }
            return data;
        },
        initHeatMap() {
            if (!this.chartHeatMap) {
                let chartDom = document.getElementById('chart_heatmap');
                this.chartHeatMap = echarts.init(chartDom);
            }

            let option = {
                title: {
                    show: true,
                    top: 20,
                    left: "center",
                    text: this.curYear + "年每日代码提交数量",
                },
                tooltip: {
                    position: 'top'
                },
                visualMap: {
                    show: false,
                    min: 0,
                    max: 10000
                },
                calendar: {
                    range: this.curYear,
                    left: 50,
                    right: 30,
                    top: 80,
                    // bottom: 40,
                    // cellSize: [15, 15],
                    dayLabel: {
                        nameMap: 'ZH'
                    },
                    monthLabel: {
                        formatter: '{MM}月'
                    },
                    yearLabel: {
                        show: false
                    },
                    label: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#555"
                        }
                    }
                },
                // 热力图小方块的颜色
                // gradientColor: [
                //     "#eee",
                //     "#39d353",
                // ],
                series: {
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    data: this.getHeatMapData(this.curYear)
                },
                useUTC: false,
            };
            option && this.chartHeatMap.setOption(option);

            this.$nextTick(() => {
                this.chartHeatMap.resize();
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.row-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.count-panel-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .count-panel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 0 20px 0 10px;
        padding-left: 30px;
        padding-right: 0;
        .desc {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            // margin: 0 20px 0 10px;
            .name {
                font-size: 14px;
                color: #888;
                margin-bottom: 15px;
            }
            .count {
                color: #333;
                font-size: 32px;
                font-weight: bold;
            }
        }
    }
}
// .stat-container {
//     width: 49%;
    .canvas-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .chart-wrapper {
            width: 95%;
            height: 90%;
            .empty-ranking {
                width: 100%;
                height: 268px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                .ranking-title {
                    color: #444;
                    font-size: 18px;
                    font-weight: bolder;
                    margin-top: 20px;
                }
                .ranking-desc {
                    color: #888;
                    font-size: 16px;
                    margin-top: 70px;
                    letter-spacing: 1px;
                }
            }
        }
    }
// }
</style>
